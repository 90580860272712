import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';

import { EventStatsOverview } from './EventStats';
import { EventsList, Event } from './EventsList';
import { useVenues } from '../providers/venues';

const plannerQuery = gql`
  query PlannerEventStats($id: ID, $venueIds: [ID!]!) {
    planner(id: $id) {
      id
      pastEventCount
      upcomingEventCount
      totalSpend
      averageSpend
      events(venueIds: $venueIds) {
        id
        status
        name
        startDate
      }
    }
  }
`;
interface PlannerEventStatsProps {
  plannerId: string;
}

export const PlannerEventStats = ({ plannerId }: PlannerEventStatsProps) => {
  const { venueIds } = useVenues();
  const variables = { id: plannerId, venueIds };
  const { data, loading } = useQuery(plannerQuery, {
    variables,
  });

  const planner = data?.planner || {};
  const events = planner.events || [];

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <EventStatsOverview
        pastEventCount={planner.pastEventCount}
        upcomingEventCount={planner.upcomingEventCount}
        totalSpend={planner.totalSpend}
        averageSpend={planner.averageSpend}
      />
      <EventsList events={events} />
    </div>
  );
};
