import { Grid } from '@mui/material';
import { Fields } from '@pv/common/components';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import { useEffect, useState } from 'react';

export const PlanOptionsSelect = ({
  includeStarter = false,
  availablePlans,
  selectedPlan,
  setSelectedPlan,
  initialBillingInterval,
  initialTier,
}) => {
  const [planTier, setPlanTier] = useState(
    initialTier || selectedPlan?.tier || 'professional'
  );

  let availableTiers = uniq(map(sortBy(availablePlans, 'monthlyRate'), 'tier'));

  if (!includeStarter) {
    availableTiers = filter(availableTiers, (tier) => tier !== 'starter');
  }

  const selectTierOptions = availableTiers.map((tier) => [
    tier,
    startCase(tier),
    false,
  ]);

  const monthlyRatesByTier = {};
  availablePlans.forEach((plan) => {
    const update = monthlyRatesByTier[plan.tier] || {};
    return (monthlyRatesByTier[plan.tier] = {
      ...update,
      ...{ [plan.billingInterval]: plan.monthlyRate / 100 },
    });
  });

  const tierRates = monthlyRatesByTier[planTier];
  const monthlyRate = tierRates?.monthly;
  const annualRate = tierRates?.annual;

  const annualDiscount = tierRates?.monthly * 12 - tierRates?.annual * 12;

  const selectIntervalOptions = [];
  if (monthlyRate) {
    selectIntervalOptions.push([
      'monthly',
      `Month-to-Month ($${monthlyRate}/mo)`,
      false,
    ]);
  }
  if (annualRate) {
    let annualDiscountText = `Yearly ($${annualRate}/mo)`;
    if (annualDiscount && annualDiscount > 0) {
      annualDiscountText = `${annualDiscountText} - Save $${annualDiscount}/yr 🎉`;
    }

    selectIntervalOptions.push([
      'annual',
      annualDiscountText,
      planTier === 'starter',
    ]);
  }

  const [planBillingInterval, setPlanBillingInterval] = useState(
    initialBillingInterval || selectedPlan?.billingInterval || selectIntervalOptions?.[0]?.[0] || 'monthly'
  );

  useEffect(() => {
    let plan;
    if (planTier === 'starter') {
      // billingInterval is always monthly for starter plan
      plan = availablePlans.find(
        (p) => p.tier === planTier && p.billingInterval === 'monthly'
      );
    } else {
      plan = availablePlans.find(
        (p) => p.tier === planTier && p.billingInterval === planBillingInterval
      );
    }
    setSelectedPlan(plan);
  }, [planTier, planBillingInterval, availablePlans, setSelectedPlan]);

  return (
    <>
      <Grid item xs={12}>
        <Fields.PvDropDown
          label="Plan Name"
          name="plan-name"
          value={planTier}
          onChange={(e) => setPlanTier(e.target.value)}
          vals={selectTierOptions}
        />
      </Grid>
      {planTier !== 'starter' && (
        <Grid item xs={12}>
          <Fields.PvDropDown
            label="Billing Period"
            name="billing-period"
            value={planBillingInterval}
            onChange={(e) => setPlanBillingInterval(e.target.value)}
            vals={selectIntervalOptions}
            disabled={planTier === 'starter'}
          />
        </Grid>
      )}
    </>
  );
};
