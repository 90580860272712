import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@mui/material';
import map from 'lodash/map';
import find from 'lodash/find';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState, useMemo } from 'react';
import { Fields } from '@pv/common/components';

import { useVenues } from '../../providers/venues';
import EventTimeEdit from './EventTimeEdit';
import SpaceSelector, {
  spaceAvailabilityQuery,
  spaceValsFromVenue,
} from '../../components/SpaceSelector';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
  content: {
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  editor: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: '60%',
    maxWidth: '100%',
  },
}));

const NewEventInfoArea = ({
  forceValidate,
  eventName,
  setEventName,
  groupSize,
  setGroupSize,
  venueId,
  setVenueId,
  spaceIds,
  setSpaceIds,
  startDate,
  setStartDate,
  startOffset,
  setStartOffset,
  endDate,
  setEndDate,
  endOffset,
  setEndOffset,
  budget,
  setBudget,
  eventType,
  setEventType,
}) => {
  const { venues } = useVenues();
  const venuesVals = map(venues, (v) => [v.id, v.internalName || v.name]);
  const showVenuesSelector = venues?.length > 1;
  const classes = useStyles();

  const skip = !venueId;

  const variables = {
    venueId,
    dateTimeRange: {
      startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
      startOffset: parseInt(startOffset),
      endOffset: parseInt(endOffset),
    },
  };

  const { data } = useQuery(spaceAvailabilityQuery, { variables, skip });
  const spaceAvailability = data?.spaceAvailability;
  const venue = venues?.find((v) => v.id === venueId);
  const multiDayEnabled =
    venue?.featureScope?.premiumFeaturesEnabled && venue?.multiDayEnabled;

  const spaceVals = useMemo(
    () => spaceValsFromVenue({ venue }),
    [venueId, venue]
  );

  useEffect(() => {
    if (forceValidate && !eventName) {
      const variant = 'error';
      enqueueSnackbar('Event Name Required', {
        variant,
        SnackbarProps: {
          'data-cy': 'event-name-required-alert',
        },
      });
    }
  }, [forceValidate]);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '16px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Add Event Info</Typography>
        </Grid>
        <Grid item xs={12}>
          <Fields.PvTextField
            autoFocus
            required
            label="Event Name"
            name="eventName"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            forceValidate={forceValidate}
            data-cy="create-event-name-text-field"
          />
        </Grid>

        <Grid item xs={12}>
          <EventTimeEdit
            classes={classes}
            startDate={startDate}
            startOffset={startOffset}
            endDate={endDate}
            endOffset={endOffset}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setStartOffset={setStartOffset}
            setEndOffset={setEndOffset}
            multiDayEnabled={multiDayEnabled}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Fields.PvIntegerField
            label="Group Size"
            name="groupSize"
            value={groupSize}
            onChange={(e) => setGroupSize(e.target.value)}
            data-cy="create-event-group-size-text-field"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Fields.PvCurrencyMaskField
            showCents={false}
            label="Budget"
            value={budget}
            name="budget"
            onChange={(e) => setBudget(e.target.value)}
            data-cy="create-event-budget-text-field"
          />
        </Grid>

        {showVenuesSelector && (
          <Grid item xs={12} sm={6}>
            <Fields.PvDropDown
              label="Venue"
              name="venue"
              vals={venuesVals}
              onChange={(e) => setVenueId(e.target.value)}
              value={venueId}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={showVenuesSelector ? 6 : 12}>
          <SpaceSelector
            spaceAvailability={spaceAvailability}
            label="Space"
            name="space"
            vals={spaceVals}
            onChange={(e) => setSpaceIds(e.target.value)}
            value={spaceIds}
          />
        </Grid>

        <Grid item xs={12}>
          {venue && venue.eventTypeOptions && (
            <Fields.PvDropDown
              variant="outlined"
              value={eventType || ''}
              label="Event Type"
              onChange={(e) => setEventType(e.target.value)}
              vals={[
                [null, 'None'],
                ...venue.eventTypeOptions.map((et) => [et, et]),
              ]}
              name="eventType"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewEventInfoArea;
