import React from 'react';
import { moneyPrint } from '@pv/common/utils';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from '@mui/material';

interface EventStatsOverviewProps {
  pastEventCount: number;
  upcomingEventCount: number;
  totalSpend: number;
  averageSpend: number;
}

export const EventStatsOverview = ({
  pastEventCount,
  upcomingEventCount,
  totalSpend,
  averageSpend,
}: EventStatsOverviewProps) => {
  return (
    <EventStatsContainer>
      <Headline text="Event Stats" />
      <TableContainer
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '6px',
          padding: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <StatsTableRow
              label="Past Events"
              value={pastEventCount ? pastEventCount.toString() : '0'}
            />
            <StatsTableRow
              label="Upcoming Events"
              value={upcomingEventCount ? upcomingEventCount.toString() : '0'}
            />
            <StatsTableRow
              label="Total Spend"
              value={totalSpend ? moneyPrint(totalSpend / 100) : '0'}
            />
            <StatsTableRow
              label="Average Spend"
              value={averageSpend ? moneyPrint(averageSpend / 100) : '0'}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </EventStatsContainer>
  );
};

const StatsTableRow = ({ label, value }: { label: string; value: string }) => {
  const padding = '8px';
  const PaddedTableCell = ({ children }: { children: React.ReactNode }) => {
    return <TableCell sx={{ padding: padding }}>{children}</TableCell>;
  };

  return (
    <TableRow>
      <PaddedTableCell>
        <Typography variant="body1">{label}</Typography>
      </PaddedTableCell>
      <PaddedTableCell>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {value}
        </Typography>
      </PaddedTableCell>
    </TableRow>
  );
};

export const EventStatsContainer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px',
        paddingTop: '16px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        gap: '12px',
      }}
    >
      {children}
    </div>
  );
};

export const Headline = ({ text }: { text: string }) => {
  return <Typography variant="subtitle1">{text}</Typography>;
};
