import { gql, useQuery } from '@apollo/client';
// import { Box, Grid, Typography } from '@material-ui/core';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Divider,
  TextField,
} from '@mui/material';
import { useTheme } from '@material-ui/styles';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState, useMemo } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import validator from 'validator';
import { useMutation } from '@pv/common/hooks';
import { Fields } from '@pv/common/components';
import { isEmpty } from 'lodash';
import { moneyPrintWithoutZeros } from '@pv/common/utils';
import { PaymentOptionsSelect } from '../pages/Settings/Organization/components/PaymentOptionsSelect';
import { PlanOptionsSelect } from '../pages/Settings/Organization/components/PlanOptionsSelect';
import { BillingChangeForm } from '../pages/Settings/Organization/components/BillingChangeForm';
import size from 'lodash/size';
import { usePlaidToken } from '../hooks/usePlaidToken';
import { createStripePaymentMethodTokenFromPlaidPayloadMutation } from '../pages/Settings/Organization/graphql';
import { Award } from 'react-feather';
import PlanChip from './PlanChip';
import { useUserAuth } from '../providers/auth';
import { Link } from 'react-router-dom';

const updateVenuePlanMutation = gql`
  mutation UpdateVenuePlan($input: UpdateVenuePlanInput!) {
    updateVenuePlan(input: $input) {
      venue {
        id
        name
        subscription {
          id
          planDefinitionId
          planName
          tier
          billingInterval
          monthlyRate
          cardApplicationFeeRate
          achApplicationFeeRate
          stripeSourceId
        }
      }
      errors {
        message
      }
    }
  }
`;

const planDefinitionsQuery = gql`
  query CustomerPlanDefinitions {
    customerPlanDefinitions {
      id
      tier
      planName
      billingInterval
      monthlyRate
      stripeProductId
      stripePriceId
      active
    }
  }
`;

const createVenueMutation = gql`
  mutation CreateNewVenue($input: CreateVenueInput!) {
    createVenue(input: $input) {
      venue {
        id
        subscription {
          planName
          planDefinitionId
          stripeSourceId
        }
      }
      errors {
        message
      }
    }
  }
`;

const preserveWhitespaceInDiff = (func) => {
  return func;
};

export const UpgradeVenuePlanModal = preserveWhitespaceInDiff(
  ({
    venue,
    open,
    onClose,
    afterConfirm,
    paymentSources,
    isCreate = false,
    organization,
    initialBillingInterval,
    initialTier,
  }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useUserAuth();

    const theme = useTheme();
    const [billingEmail, setBillingEmail] = useState();
    const [stripeLoading, setStripeLoading] = useState(false);
    const [forceValidate, setForceValidate] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [venueName, setVenueName] = useState('');
    const [currentPlan, setCurrentPlan] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedSourceId, setSelectedSourceId] = useState(
      venue?.subscription?.stripeSourceId
    );
    const [planChangeReason, setPlanChangeReason] = useState('');

    const [cardholderName, setCardholderName] = useState(user?.fullName || '');
    const creditCardEnabled = organization?.isCcPaymentEnabled;
    const [stripePaymentMethodToken, setStripePaymentMethodToken] = useState();
    const plaidTokenData = usePlaidToken({ clientUserId: user?.id });
    const [plaidPayload, setPlaidPayload] = useState();
    const { data, loading: plansLoading } = useQuery(planDefinitionsQuery);
    const availablePlans = useMemo(
      () => data?.customerPlanDefinitions || [],
      [data?.customerPlanDefinitions]
    );

    useEffect(() => {
      setVenueName(venue?.name);
    }, [venue]);

    const [updateVenuePlan, { loading: updateLoading }] = useMutation(
      updateVenuePlanMutation,
      {
        onNoErrorsCompleted: () => {
          enqueueSnackbar('Updated Plan', { variant: 'success' });
          setStripeLoading(false);
          onClose();
          if (afterConfirm) {
            afterConfirm();
          }
        },
      }
    );

    const [createVenue, { loading: createLoading }] = useMutation(
      createVenueMutation,
      {
        onNoErrorsCompleted: () => {
          enqueueSnackbar('Venue has been created', { variant: 'success' });
          setStripeLoading(false);
          setVenueName('');
          onClose();
          if (afterConfirm) {
            afterConfirm();
          }
        },
      }
    );
    const [
      createStripePaymentMethodTokenFromPlaidPayload,
      { loading: plaidLoading },
    ] = useMutation(createStripePaymentMethodTokenFromPlaidPayloadMutation, {
      onNoErrorsCompleted: (data) => {
        setStripePaymentMethodToken(
          data.createStripePaymentMethodTokenFromPlaidPayload.stripeToken
        );
      },
    });

    const onChangeVenueName = (e) => {
      setVenueName(e.target.value);
      setNameError(false);
    };

    const onConfirm = async () => {
      const input = {
        planDefinitionId:
          selectedPlan?.id || venue?.subscription.planDefinitionId,
      };
      let stripeTokenId;

      if (isEmpty(paymentSources)) {
        setStripeLoading(true);

        try {
          stripeTokenId = await getStripePaymentMethodToken();
        } catch (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
          setStripeLoading(false);
          return;
        }

        if (!billingEmail || !validator.isEmail(billingEmail)) {
          setForceValidate(true);
          setStripeLoading(false);
          return;
        }

        setStripeLoading(false);
      }
      if (isCreate && isEmpty(venueName)) {
        setForceValidate(true);
        setNameError(true);
        setStripeLoading(false);
        return;
      }

      if (stripeTokenId) {
        input.stripeTokenId = stripeTokenId;
        input.billingEmail = billingEmail;
      }

      if (isCreate) {
        input.organizationId = organization.id;
        input.name = venueName;
      } else {
        input.id = venue.id;
        input.note = planChangeReason;
      }
      if (selectedSourceId) {
        input.stripeSourceId = selectedSourceId;
      }
      const variables = { input };
      isCreate ? createVenue({ variables }) : updateVenuePlan({ variables });
    };

    const getStripePaymentMethodToken = async () => {
      if (plaidPayload && stripePaymentMethodToken) {
        return stripePaymentMethodToken;
      } else if (creditCardEnabled) {
        const cardElement = elements.getElement('card');
        const { token } = await stripe.createToken(cardElement, {
          name: cardholderName || billingEmail || 'none',
        });
        if (!token) {
          setForceValidate(true);
          setStripeLoading(false);
          return;
        }
        return token.id;
      }
    };

    const handlePlaidPayloadReceipt = (payload) => {
      setPlaidPayload(payload);
      createStripePaymentMethodTokenFromPlaidPayload({
        variables: { input: { stripePlaidPayload: payload } },
      });
    };

    useEffect(() => {
      if (!availablePlans.length) {
        return;
      }
      if (initialTier && initialBillingInterval) {
        const plan = availablePlans.find(
          (p) =>
            p.tier === initialTier &&
            p.billingInterval === initialBillingInterval
        );
        setSelectedPlan(plan);
        setCurrentPlan(plan);
      }
    }, [availablePlans, initialBillingInterval, initialTier]);

    useEffect(() => {
      if (venue?.subscription && !initialTier && !initialBillingInterval) {
        const planToSelect = availablePlans.find(
          (p) =>
            p.tier === venue.subscription.tier &&
            p.billingInterval === venue.subscription.billingInterval
        );
        if (planToSelect) {
          setSelectedPlan(planToSelect);
          setCurrentPlan(planToSelect);
        } else {
          const defaultPlan = availablePlans.find(
            (p) => p.tier === 'professional' && p.billingInterval === 'monthly'
          );
          setSelectedPlan(defaultPlan);
        }
      }
    }, [venue, availablePlans, initialTier, initialBillingInterval]);

    useEffect(() => {
      setBillingEmail(organization?.billingEmail || '');
    }, [open, organization?.billingEmail]);

    useEffect(() => {
      if (venue?.subscription?.stripeSourceId) {
        setSelectedSourceId(venue.subscription.stripeSourceId);
      }
    }, [open, venue?.subscription?.stripeSourceId]);

    const loading =
      updateLoading ||
      stripeLoading ||
      plansLoading ||
      createLoading ||
      plaidLoading;

    const discount = organization?.discount || 0;
    const monthlyRate = selectedPlan?.monthlyRate / 100.0 || 0.0;

    const monthlyRateWithDiscount = monthlyRate * (1 - discount);

    const includeStarter = !isCreate && size(organization?.venues) === 1;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        PaperProps={{
          component: 'form',
          onSubmit: (e) => {
            e.preventDefault();
            onConfirm();
          },
        }}
        data-cy="upgrade-venue-plan-modal"
      >
        <DialogTitle>{isCreate ? 'Add a Venue' : 'Select a Plan'}</DialogTitle>
        <Divider />
        <DialogContent data-cy="upgrade-venue-plan-modal-content">
          <Grid container spacing={2}>
            {isCreate ? (
              <Grid item xs={12}>
                <Fields.PvTextField
                  required
                  forceValidate={forceValidate}
                  name="venue-name"
                  label="Venue Name"
                  value={venueName}
                  forceError={nameError}
                  onChange={onChangeVenueName}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h5" style={{ marginBottom: '24px' }}>
                  {venueName}
                </Typography>
              </Grid>
            )}

            <PlanOptionsSelect
              includeStarter={includeStarter}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              availablePlans={availablePlans}
              initialTier={initialTier}
              initialBillingInterval={initialBillingInterval}
            />

            {monthlyRate > 0 &&
              (!isEmpty(paymentSources) ? (
                <Grid item xs={12}>
                  <PaymentOptionsSelect
                    onSelect={setSelectedSourceId}
                    paymentSources={paymentSources}
                    selectedSourceId={selectedSourceId}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <BillingChangeForm
                    creditCardEnabled={creditCardEnabled}
                    billingEmail={billingEmail}
                    setBillingEmail={setBillingEmail}
                    forceValidate={forceValidate}
                    setForceValidate={setForceValidate}
                    cardholderName={cardholderName}
                    setCardholderName={setCardholderName}
                    plaidTokenData={plaidTokenData}
                    plaidPayload={plaidPayload}
                    handlePlaidPayloadReceipt={handlePlaidPayloadReceipt}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: '16px',
                  gap: '12px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '8px',
                  boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.12)',
                }}
              >
                {selectedPlan?.tier !== 'starter' && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h5">
                      {discount > 0 && (
                        <>
                          <strike>
                            {selectedPlan?.billingInterval === 'annual' &&
                              `${moneyPrintWithoutZeros(
                                monthlyRate * 12
                              )} billed annually`}
                            {selectedPlan?.billingInterval === 'monthly' &&
                              `$${monthlyRate} billed monthly`}
                          </strike>
                          <br />
                        </>
                      )}
                      <span>
                        {selectedPlan?.billingInterval === 'annual' &&
                          `${moneyPrintWithoutZeros(
                            monthlyRateWithDiscount * 12
                          )} billed annually`}
                        {selectedPlan?.billingInterval === 'monthly' &&
                          `${moneyPrintWithoutZeros(
                            monthlyRateWithDiscount
                          )} billed monthly`}
                        {discount > 0 && ` after ${discount * 100}% discount`}
                      </span>
                    </Typography>
                    <PlanChip plan={selectedPlan?.tier} />
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Award
                    style={{
                      color: theme.palette.secondary.main,
                      marginRight: '4px',
                    }}
                  />
                  <Typography variant="body1">
                    Risk-free <strong>30 Day Money Back Guarantee</strong> for
                    all plans
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {!isCreate && selectedPlan?.tier !== currentPlan?.tier && (
              <Grid item xs={12}>
                <TextField
                  label="Plan Change Reason"
                  rows="4"
                  placeholder="Tell us why you're changing your plan..."
                  value={planChangeReason}
                  onChange={(e) => {
                    setPlanChangeReason(e.target.value);
                  }}
                  required
                  fullWidth
                  multiline
                  data-cy="plan-change-reason"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body2">
                By starting your subscription, you agree to our{' '}
                <Link
                  to="https://www.perfectvenue.com/terms-of-service"
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    component="span"
                    style={{
                      color: theme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                    variant="body2"
                  >
                    Terms of Service
                  </Typography>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '24px' }}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-cy="modal-submit-button"
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default UpgradeVenuePlanModal;
