import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { initSentry } from '@pv/common/sentry';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'devextreme/dist/css/dx.common.css';
import './extensions/devextreme/mui.css';

const pvEnv = process.env.REACT_APP_PV_ENV;
if (pvEnv === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-PL43NJN',
  });
}

const dsn = process.env.REACT_APP_SENTRY_DSN as string;
initSentry({ dsn });

ReactDOM.render(
  <React.StrictMode>

    <App />

  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
